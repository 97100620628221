import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import Mail from "../components/mail"

const Appointment = props => {
  return (
    <Layout {...props}>
      <SEO title="Afspraak" />

      <Section>
        <article className="prose lg:prose-xl">
          <h1>Maak een afspraak</h1>
        </article>
      </Section>

      <Section full background="bg-gradient-to-r from-gray-200 to-white">
        <article className="prose lg:prose-xl">
          <h2>Wanneer kan u een afspraak maken?</h2>
          <ul>
            <li>Even weken (ma-vr): 19u-21u</li>
            <li>Oneven weken (ma-vr): 09u-15u</li>
          </ul>

          <h2>Hoe kan u een afspraak maken?</h2>
          <p>
            Stuur ons een mail naar&nbsp;
            <b>
              <Mail />
            </b>{" "}
            met de nodige informatie (zie hieronder) en we nemen zo snel
            mogelijk contact op met je!
          </p>
        </article>
      </Section>

      <Section>
        <article className="prose lg:prose-xl">
          <h2>Welke basisgegevens hebben we nodig van jou?</h2>
          <p>
            De gegevens in het <b>vet</b> zijn verplicht!
          </p>
          <ul>
            <li>
              <b>Naam & voornaam</b>
            </li>
            <li>
              <b>Telefoonnummer</b>
            </li>
            <li>
              <b>Email</b>
            </li>
            <li>Adres</li>
          </ul>

          <h2>Vragenlijst voor je met de behandeling begint</h2>
          <p>
            De gegevens in het <b>vet</b> zijn verplicht!
          </p>
          <ul>
            <li>Huidtype</li>
            <li>Haarkleur</li>
            <li>Heeft u een bloedziekte?</li>
            <li>Heeft u een huidaandoening?</li>
            <li>Heeft u een huidkanker?</li>
            <li>
              <b>Is uw haargroei normaal?</b>
            </li>
            <li>
              <b>Hoe heeft u de haren voorgaand te voren verwijderd?</b>
            </li>
            <li>
              <b>Bent u zwanger?</b>
            </li>
            <li>
              <b>
                Hoeveel weken is het geleden dat het te behandelen gebied bij
                gebruind is door zon of zonnebank?
              </b>
            </li>
            <li>
              <b>Gebruikt u medicijnen: zo ja, welke? en voor welke ziekte?</b>
            </li>
          </ul>
        </article>
      </Section>
    </Layout>
  )
}

export default Appointment
